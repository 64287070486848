import { template as template_99c867a886924bfe94f791ca07e2ceb4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_99c867a886924bfe94f791ca07e2ceb4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
