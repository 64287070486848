import { template as template_221182a134fd4729894e136be0d6c5fe } from "@ember/template-compiler";
const FKLabel = template_221182a134fd4729894e136be0d6c5fe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
