import { template as template_78582baa89ca4344aa714b817e816a0f } from "@ember/template-compiler";
const FKText = template_78582baa89ca4344aa714b817e816a0f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
